export interface QuestionData {
    id: number | string
    title: string
    answer: string
    created_at?: string
    quiz_id?: string
    info_link?: string
    a?: string
    b?: string
    c?: string
    d?: string
    e?: string
    f?: string
}

export interface QuizData {
    id?: number
    quiz_id?: number
    sim_id?: number
    title: string
    description?: string
    locked: boolean
    questions: QuestionData[]
    code: string
}

export enum VenueType {
    CLASSROOM = "classroom",
    MEDIUM = "medium",
    LARGE = "large",
}

export enum UnitType {
    SECOND = "second",
    MINUTE = "minute",
    HOUR = "hour",
    DAY = "day",
}

export enum PushType {
    AUTO = "auto",
    MANUAL = "manual",
}

export enum AlertType {
    SUCCESS = "success",
    ERROR = "error",
}

export enum InfectionRange {
    IMMEDIATE = "IMMEDIATE",
    STRONG = "STRONG",
    MEDIUM = "MEDIUM",
    WEAK = "WEAK",
    EXTREMELY_WEAK = "EXTREMELY_WEAK",
}

export enum UIStyle {
    DEFAULT = 0,
    FATHOM = 1,
}

export enum QRCodeType {
    VACCINE = "vaccine",
    PPE = "ppe",
    MASK = "mask",
    TEST = "test",
    FOOD = "food",
    MEDICATION = "medication",
    QUIZ = "quiz",
    SEQUENCING = "sequencing",
}

export const QRCodeList = [
    QRCodeType.VACCINE,
    QRCodeType.PPE,
    QRCodeType.MASK,
    QRCodeType.TEST,
    QRCodeType.FOOD,
    QRCodeType.MEDICATION,
    QRCodeType.QUIZ,
]

export interface QRCodeBody {
    code: string
    type: QRCodeType
    count: number
}

export interface Simulation {
    id?: number
    uuid: string
    name: string
    code: string
    start_date: Date
    end_date: Date
    archived: boolean
    size: number
    venue: VenueType
    unit_type: UnitType
    time_step: number
    time_zone_IANA: string
    ui_style: number
    epi_generations: number
    number_of_contacts: number
    number_of_contacts_in: number
    qr_support: boolean
    food_support: boolean
    score_support: boolean
    sequence_support: boolean
    food_interval: number
    use_location: boolean
    use_beacon: boolean
    number_of_strains: number
    pathogen_id: number
    pathogen_name?: string
    pathogen?: Pathogen
    is_modified: boolean
    rewards: RewardsInfo
}

export interface RewardsInfo {
    min_questions: number
    min_score_mask: number
    min_score_ppe: number
    mask_period: number
    ppe_period: number
}

export interface Pathogen {
    id: number
    name: string
    infection_prob: InfectionProb
    infection_info: InfectionInfo
    protection_info: ProtectionInfo
    fpos_info: FPosInfo
    source_range: number
    infection_range: InfectionRange
    doses_per_vaccine: number
    vaccine_range: number
    mutation_rate: number
    seed_parameters: SeedParameters
}

export interface SeedParameters {
    basic_reproduction_number: number
    exposed_to_inf_mild_ratio: number
    mean_symp_onset_to_inf_mild_ratio: number
    sdev_symp_onset_to_inf_mild_ratio: number
    inf_severe_to_inf_mild_ratio: number
    asym_to_inf_mild_ratio: number
    asym_pinf_factor: number
    mild_pinf_factor: number
    severe_pinf_factor: number
    prevalance_similar_disease: number
}
export interface InfectionProb {
   from_source: number
   from_peers: number
   from_asym_peers: number
   from_mild_peers: number
   from_severe_peer: number
}

export interface InfectionInfo {
    mild_from_asym: number
    severe_from_mild: number
    from_asym_peers: number
    from_mild_peers: number
    from_severe_peer: number
    asym_prevalance: number
 }

 export interface ProtectionInfo {
    from_source: number
    from_peers: number
    from_asym_peers: number
    from_mild_peers: number
    from_severe_peer: number
 }

 export interface FPosInfo{
    prob: number
    mean: number
    sdev: number
    min_symptoms: number
    symptoms: [Symptom]
}
export interface Symptom {
    name: string
    label: string
    description: string
    url: string
    prob: number
}
export interface APISymptom {
    name: string
    label: string
    description: string
    url: string
    id: number
}

import React from 'react'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { Link, useHistory } from 'react-router-dom'

import { Flex } from '../styles/global'
import { AUTH_USER_TOKEN_KEY, checkAdmin } from '../utils/auth.utils'

export const menuRoutes = [
    {
      name: 'Simulations',
      route: '/',
      icon: '',
    },
    {
      name: 'Pathogens',
      route: '/pathogens',
      icon: '',
      adminOnly: true,
    },
    {
      name: 'Symptoms',
      route: '/symptoms',
      icon: '',
      adminOnly: true,
    },
]


export const Menu = () => {
  const history = useHistory();
    return (
      <div>
        <MenuDrawer
          variant="permanent"
          anchor="left"
        >
        <Branding direction="column" justify="center" alignItems="center">
          <h4>Operation Outbreak</h4>
        </Branding>
        <Divider />
        <List>
          {menuRoutes.map((item, index) => {
            if(!item.adminOnly || (item.adminOnly && checkAdmin())){
              return (
                <StyledLink key={item.name} to={item.route}>
                  <ListItem button key={item.name}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                </StyledLink>
              )
            }
            return null
          })}
          <div>
            <ListItem
              button
              key='signout'
              onClick={()=>{
                localStorage.setItem(AUTH_USER_TOKEN_KEY, "")
                history.push('/login');
              }}
            >
              <ListItemText primary="Sign Out" />
            </ListItem>
          </div>
        </List>
        <Divider />
      </MenuDrawer>
      </div>
    )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`

const MenuDrawer = styled(Drawer)`
    .MuiPaper-root {
        width: 200px;
    }
`

const Branding = styled(Flex)`
  padding: 10px;

  > h4 {
    padding-bottom: 10px;
    font-size: 20px;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    text-align: center;
  }

  > img {
    width: 100px;
  }
`
